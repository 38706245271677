.login{
    margin-top: 8rem;
 }

.login h2 {
     font-family: 'Montserrat', sans-serif;
     color:#174873;
 }
 .login ::placeholder{
     color:rgba(143, 137, 137, 0.863) !important;
 }

 ::-webkit-input-placeholder {
    color: #FF0000;
    opacity: 1 !important; /* for older chrome versions. may no longer apply. */
}

:-moz-placeholder { /* Firefox 18- */
    color: #FF0000;
    opacity: 1 !important;
}

::-moz-placeholder {  /* Firefox 19+ */
    color: #FF0000;
    opacity: 1 !important;
}

:-ms-input-placeholder {  
   color: #FF0000;
}