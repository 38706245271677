.softwareName {
    color: #174873;
    font-size: 1.5em;
    letter-spacing: .05em;
    font-family: B612, sans-seri;
}

/* -------------------------------------------Media Query--------------------------------------- */

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .softwareName {
        color: #174873;
        font-size: 100%;
        letter-spacing: .05em;
        font-family: B612, sans-seri;
    }

    .userName{
        margin-right: .5em;
    }
}